import { createContext, useState, useEffect, useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import OpenReplayTracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

import Spinner from "./components/Spinner";
import Error500 from "./components/Error500";
import NotificationsProvider from "./components/NotificationsProvider";
import AppRoutes from "./AppRoutes";

import { CurrentUser } from "./models/users";
import { DashboardFilters } from "./models/filters";

import useAuthTokenStore from "./hooks/authTokenStore";
import { getCurrentUser, refreshToken } from "./hooks/api";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const FiltersContext = createContext<{
  filters: DashboardFilters;
  setFilters: React.Dispatch<React.SetStateAction<DashboardFilters>>;
}>({
  filters: {
    timeRange: {
      start: dayjs().subtract(1, "month").startOf("day"),
      end: dayjs().endOf("day"),
    },
  },
  setFilters: () => {},
});

interface UserContextType {
  currentUser: CurrentUser | null;
  resetUser: () => void;
}

const UserContext = createContext<UserContextType>({
  currentUser: null,
  resetUser: () => {},
});

const WebSocketContext = createContext<WebSocket | null>(null);

export const useWebSocket = () => {
  const ws = useContext(WebSocketContext);

  return ws;
};

const AppRoutesWrapper: React.FC = () => {
  const loggedInAsOtherUser = useAuthTokenStore(
    (state) => state.loggedInAsOtherUser
  );
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [error, setError] = useState(false);
  const token = useAuthTokenStore((state) => state.token);
  const [filters, setFilters] = useState<DashboardFilters>({
    timeRange: {
      start: dayjs().subtract(1, "month").startOf("day"),
      end: dayjs().endOf("day"),
    },
  });

  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    if (!currentUser) return;

    if (currentUser.admin || loggedInAsOtherUser) {
      return;
    }

    if (window.location.hostname === "localhost") {
      return;
    }

    const tracker = new OpenReplayTracker({
      projectKey: "8Yemdg7P77rDJJO6UvVE",
    });

    tracker.use(trackerAssist({}));

    tracker.start({
      userID: currentUser.id,
      metadata: {
        name: currentUser.name || "",
        email: currentUser.email,
      },
    });
  }, [currentUser, loggedInAsOtherUser]);

  useEffect(() => {
    setFilters({
      timeRange: {
        start: dayjs().subtract(1, "month").startOf("day"),
        end: dayjs().endOf("day"),
      },
    });
  }, [currentUser, loggedInAsOtherUser]);

  useEffect(() => {
    getCurrentUser()
      .then((res) => {
        setCurrentUser(res.data);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  useEffect(() => {
    if (token) {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}?token=${token}`
      );
      setWebSocket(ws);
    }
  }, [token]);

  const resetUser = () => {
    setCurrentUser(null);
  };

  if (error) {
    return <Error500 />;
  }

  if (!currentUser) {
    return <></>;
  }

  return (
    <UserContext.Provider value={{ currentUser, resetUser }}>
      <WebSocketContext.Provider value={webSocket}>
        <NotificationsProvider>
          <FiltersContext.Provider
            value={{
              filters,
              setFilters,
            }}
          >
            <AppRoutes />
          </FiltersContext.Provider>
        </NotificationsProvider>
      </WebSocketContext.Provider>
    </UserContext.Provider>
  );
};

function App() {
  const token = useAuthTokenStore((state) => state.token);
  const setToken = useAuthTokenStore((state) => state.setToken);
  const setLoggedInAsOtherUser = useAuthTokenStore(
    (state) => state.setLoggedInAsOtherUser
  );

  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      setToken(sessionStorage.getItem("token") as string);
      setLoggedInAsOtherUser(true);
      return;
    }
    if (token !== null) return;

    refreshToken()
      .then((res: AxiosResponse) => {
        setToken(res.data.token);
      })
      .catch(() => {
        window.location.replace(
          process.env.REACT_APP_LOGIN_URL +
            "" +
            "?redirectUrl=" +
            window.location.href
        );
      });
  }, [token, setToken, setLoggedInAsOtherUser]);

  if (!token) {
    return (
      <div className="flex justify-center p-3">
        <Spinner />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#6334cc",
          },
        }}
      >
        {/* The SyleProvider is used to avoid the overriding of antd css by tailwind

          More details here : https://github.com/ant-design/ant-design/issues/38794#issuecomment-1328262525
          
          */}
        <StyleProvider hashPriority="high">
          <AppRoutesWrapper />
        </StyleProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
export { UserContext };
