import {
  MouseEvent,
  cloneElement,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import {
  BiSolidUser,
  BiSolidHourglass,
  BiSolidSpreadsheet,
} from "react-icons/bi";
import { AiFillHome } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import { CiSquareChevLeft, CiSquareChevRight } from "react-icons/ci";
import { MdLock } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { GiWhistle } from "react-icons/gi";

import notifIconOn from "./../icons/notifIconOn.svg";
import notifIconOff from "./../icons/notifIconOff.svg";

import logo from "./../logo_allobrain_black.svg";
import smallLogo from "./../logo_allobrain_small.png";
import useBrowserLanguage from "../hooks/useBrowserLanguage";
import useAccess from "../hooks/access";
import clsx from "clsx";
import useNotifications from "../hooks/notifications";
import useIsMobile from "../hooks/useIsMobile";
import { UserContext } from "../App";
import useLayout from "../hooks/useLayout";

const NotificationIcon: React.FC<{
  hasNotification: boolean;
  className?: string;
}> = ({ hasNotification, className }) => {
  if (hasNotification) {
    return (
      <img src={notifIconOn} alt="notification icon" className={className} />
    );
  }

  return (
    <img src={notifIconOff} alt="notification icon" className={className} />
  );
};

const MenuItem: React.FC<{
  text: string;
  icon: JSX.Element;
  to: string;
  reduced: boolean;
  onHover: () => void;
  onLeave: () => void;
  onClick: (e: MouseEvent) => void;
}> = ({ text, icon, to, reduced, onHover, onLeave, onClick }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [clonedIcon, setClonedIcon] =
    useState<React.FunctionComponentElement<any>>();

  useEffect(() => {
    setClonedIcon(
      cloneElement(icon, { className: "w-5 h-5 " + icon.props.className })
    );
  }, [icon]);

  useEffect(() => {
    if (to === "/") {
      setIsActive(location.pathname === to);
    } else {
      setIsActive(location.pathname.startsWith(to));
    }
  }, [location, to]);

  return (
    <Link
      to={to}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onClick}
    >
      <div className={clsx("flex items-center h-[56px]")}>
        <div
          className={clsx(
            "hover:bg-gray-200 rounded-md py-4 w-full flex items-center transition-all duration-[500ms] ease-in-out",
            reduced ? "h-[45px]" : "h-[56px]",
            reduced && "flex items-center justify-center px-0",
            !reduced && "px-4",
            isActive && "text-gray-950",
            !isActive && "text-gray-400"
          )}
        >
          {clonedIcon}
          {!reduced && <span className="ml-3">{text}</span>}
        </div>
      </div>
    </Link>
  );
};

const MenuItemMemo = memo(MenuItem, (prevProps, nextProps) => {
  return (
    prevProps.text === nextProps.text &&
    prevProps.to === nextProps.to &&
    prevProps.reduced === nextProps.reduced &&
    prevProps.icon === nextProps.icon
  );
});

const LeftMenu: React.FC = () => {
  const {
    leftMenu: { setHovered, isCollapsed, setForceExpanded },
  } = useLayout();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { getTextInBrowserLanguage } = useBrowserLanguage();
  const { can, role, isAdmin } = useAccess();
  const { currentUser } = useContext(UserContext);
  const [items, setItems] = useState<any>([]);

  const { setNotificationBarOpen, hasNewNotifications } = useNotifications();
  const isMobile = useIsMobile();

  useEffect(() => {
    let ITEMS = [
      {
        text: getTextInBrowserLanguage({ en: "Home", fr: "Accueil" }),
        icon: <AiFillHome />,
        to: "/",
      },
      {
        text: getTextInBrowserLanguage({ en: "Campaigns", fr: "Campagnes" }),
        icon: <BiSolidSpreadsheet />,
        to: "/campaigns",
      },
      {
        text: getTextInBrowserLanguage({
          en: "Organization",
          fr: "Organisation",
        }),
        icon: <CgOrganisation />,
        to: "/organization",
      },
    ];

    if (role === "agent") {
      ITEMS = [
        {
          text: getTextInBrowserLanguage({ en: "Home", fr: "Accueil" }),
          icon: <AiFillHome />,
          to: "/",
        },
        {
          text: getTextInBrowserLanguage({ en: "Campaigns", fr: "Campagnes" }),
          icon: <BiSolidSpreadsheet className="" />,
          to: "/campaigns",
        },
      ];
    }

    if (can("can_view_usage")) {
      ITEMS.push({
        text: getTextInBrowserLanguage({ en: "Usage", fr: "Utilisation" }),
        icon: <BiSolidHourglass />,
        to: "/usage",
      });
    }

    if (isAdmin) {
      ITEMS = [
        {
          text: getTextInBrowserLanguage({ en: "Admin", fr: "Admin" }),
          icon: <MdLock />,
          to: "/",
        },
        {
          text: getTextInBrowserLanguage({
            en: "Organisations",
            fr: "Organisations",
          }),
          icon: <CgOrganisation />,
          to: "/organisations",
        },
        {
          text: getTextInBrowserLanguage({ en: "Campaigns", fr: "Campagnes" }),
          icon: <BiSolidSpreadsheet />,
          to: "/campaigns",
        },
        {
          text: getTextInBrowserLanguage({ en: "Users", fr: "Utilisateurs" }),
          icon: <FaUsers />,
          to: "/users",
        },
      ];
    }

    ITEMS.push({
      text: getTextInBrowserLanguage({ en: "Profile", fr: "Profil" }),
      icon: <BiSolidUser />,
      to: "/profile",
    });

    if (!isMobile && !isAdmin) {
      ITEMS.push({
        text: getTextInBrowserLanguage({
          en: "Notifications",
          fr: "Notifications",
        }),
        icon: <NotificationIcon hasNotification={hasNewNotifications} />,
        to: "/notifications",
      });
    }

    if (currentUser && currentUser.id === "65b0e89a738e14cdb7a29d48") {
      ITEMS.push({
        text: getTextInBrowserLanguage({
          en: "Coaching",
          fr: "Coaching",
        }),
        icon: <GiWhistle />,
        to: "/coaching",
      });
    }

    setItems(ITEMS);
  }, [
    hasNewNotifications,
    role,
    isAdmin,
    can,
    getTextInBrowserLanguage,
    isMobile,
    currentUser,
  ]);

  const onHover = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => {
      setHovered(true);
      timeoutRef.current = null;
    }, 1000);
  }, [setHovered]);

  const onLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setHovered(false);
  }, [setHovered]);

  const onItemLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const onNotificationClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onItemLeave();

      setNotificationBarOpen((prev: boolean) => !prev);
    },
    [onItemLeave, setNotificationBarOpen]
  );

  return (
    <aside
      className={clsx(
        "h-full bg-white border-r transition-all duration-[500ms] ease-in-out overflow-x-hidden p-1 flex-grow-0 flex-shrink-0 flex flex-col",
        isCollapsed && "w-[64px]",
        !isCollapsed && "w-[220px]"
      )}
      onMouseLeave={onLeave}
    >
      {isCollapsed && (
        <Link to="/">
          <div className="h-[84px] w-[45px] flex items-center justify-center mx-auto ">
            <div className="rounded-lg  p-2">
              <img
                src={smallLogo}
                alt="allobrain logo"
                className="max-h-full mx-auto my-0"
                style={{
                  borderRadius: "20%",
                }}
              />
            </div>
          </div>
        </Link>
      )}

      {!isCollapsed && (
        <Link to="/">
          <div className=" py-3  mx-auto w-[190px]">
            <div className="h-[60px] rounded-lg  px-6 flex items-center justify-center">
              <img
                src={logo}
                alt="allobrain logo"
                className="max-h-full mx-auto my-0"
              />
            </div>
          </div>
        </Link>
      )}

      <div
        className={clsx(
          "mx-auto flex-grow",
          !isCollapsed && " w-[190px]",
          isCollapsed && "w-[45px]"
        )}
      >
        {items.map((item: any) => (
          <MenuItemMemo
            key={item.to}
            text={item.text}
            icon={item.icon}
            to={item.to}
            reduced={isCollapsed}
            onHover={onHover}
            onLeave={onItemLeave}
            onClick={
              item.to === "/notifications" ? onNotificationClick : onItemLeave
            }
          />
        ))}
      </div>

      {!isMobile && (
        <div
          className={clsx(
            "flex justify-end h-[45px] transition-all duration-[500ms] ease-in-out",
            !isCollapsed && "px-4 my-4",
            isCollapsed && "px-1 my-2"
          )}
        >
          <div
            className={clsx(
              "border border-gray-200 rounded-md py-4 w-full flex ",
              " items-center justify-center ",
              "text-gray-400 cursor-pointer",
              " hover:bg-gray-50"
            )}
            onClick={() => {
              if (isCollapsed) {
                setForceExpanded(true);
              } else {
                setForceExpanded(false);
                setHovered(false);
              }
            }}
          >
            {isCollapsed && <CiSquareChevRight className="w-7 h-7 " />}
            {!isCollapsed && <CiSquareChevLeft className="w-7 h-7 " />}
          </div>
        </div>
      )}
    </aside>
  );
};

export default LeftMenu;
