import axios from "axios";

import { CampaignPost, CampaignUpdate } from "../models/campaigns";
import {
  EvaluationGrid,
  EvaluationGridTypeEnum,
  MultipleLevelsEvaluationGridItemLevel,
} from "../models/evaluationGrids";
import {
  ConversationTypeEnum,
  Evaluation,
  Email,
  Chat,
} from "../models/conversations";
import { TranscriptRow } from "../models/transcripts";
import { DashboardFilters, EvaluationItemFilter } from "../models/filters";
import { CampaignCustomAnalysesUpdate } from "../models/customAnalyses";

import qs from "qs";
import useAuthTokenStore from "./authTokenStore";
import { RcFile } from "antd/es/upload";
import { Notification } from "../models/notifications";
import { CampaignPolicy } from "../models/campaignUsers";

const LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL;

export const refreshToken = () => {
  return axios.post(`${LOGIN_API_URL}/token/refresh`, null, {
    withCredentials: true,
  });
};

export const logout = () => {
  return axios.post(`${LOGIN_API_URL}/logout`, null, {
    withCredentials: true,
  });
};

export const generateUserToken = (userId: string) => {
  return axios.post(
    `${LOGIN_API_URL}/token`,
    {
      userId,
    },
    {
      headers: {
        Authorization: "Bearer " + useAuthTokenStore.getState().token,
      },
    }
  );
};

const API_URL = process.env.REACT_APP_API_URL;
const jwtInstance = axios.create({ baseURL: API_URL });

jwtInstance.interceptors.request.use((request) => {
  request.headers.set(
    "Authorization",
    "Bearer " + useAuthTokenStore.getState().token
  );

  return request;
});

jwtInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;

    if (response?.status !== 401) {
      return Promise.reject(error);
    }

    if (useAuthTokenStore.getState().loggedInAsOtherUser) {
      sessionStorage.removeItem("token");
      useAuthTokenStore.setState({
        loggedInAsOtherUser: false,
        token: null,
      });
      return Promise.reject(error);
    }

    return refreshToken()
      .then((res) => {
        useAuthTokenStore.getState().setToken(res.data.token);
        config.headers.set("Authorization", "Bearer " + res.data.token);

        return axios(config)
          .then((res) => {
            return Promise.resolve(res);
          })
          .catch((err) => {
            useAuthTokenStore.getState().removeToken();
            window.location.replace(
              process.env.REACT_APP_LOGIN_URL +
                "?redirectUrl=" +
                window.location.href
            );
          });
      })
      .catch((err) => {
        useAuthTokenStore.getState().removeToken();
        window.location.replace(
          process.env.REACT_APP_LOGIN_URL +
            "?redirectUrl=" +
            window.location.href
        );
      });
  }
);

export const getCurrentUser = () => {
  return jwtInstance.get(`/users/me`);
};

export const updateUser = (userId: string, update: any) => {
  return jwtInstance.patch(`/users/${userId}`, update);
};

export const getCurrentUserOrganisation = () => {
  return jwtInstance.get(`/users/me/organisation`);
};

export const getCampaigns = () => {
  return jwtInstance.get("/campaigns");
};

export const getUserCampaigns = (userId: string) => {
  return jwtInstance.get(`/users/${userId}/campaigns`);
};

export const uploadLogo = (logo: RcFile, campaignId: string) => {
  const logoData = new FormData();
  logoData.append("logo", logo);
  return jwtInstance.post(`/campaigns/${campaignId}/logo`, logoData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createCampaign = (campaign: CampaignPost) => {
  return jwtInstance.post("/campaigns", campaign);
};

export const duplicateCampaign = (
  campaignId: string,
  payload: { name: string; organisation_id?: string }
) => {
  return jwtInstance.post(`/campaigns/${campaignId}/duplicate`, payload);
};

export const getCampaign = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}`);
};

export const updateCampaign = (campaignId: string, update: CampaignUpdate) => {
  return jwtInstance.patch(`/campaigns/${campaignId}`, update);
};

export const getCampaignCustomAnalyses = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/custom-analyses`);
};

export const updateCustomAnalyses = (
  customAnalysesId: string,
  update: CampaignCustomAnalysesUpdate
) => {
  return jwtInstance.patch(`/custom-analyses/${customAnalysesId}`, update);
};

export const deleteCampaign = (campaignId: string) => {
  return jwtInstance.delete(`/campaigns/${campaignId}`);
};

export type GetCampaignCallsFilters = {
  agents?: string[];
  statuses?: string[];
  topics?: string[];
  score?: {
    min?: number;
    max?: number;
  };
  audio_duration?: {
    min?: number;
    max?: number;
  };
  conversation_timestamp?: {
    min?: number;
    max?: number;
  };
  metadata?: {
    [key: string]: string;
  };
  eval_items?: EvaluationItemFilter[];
};

export const getCampaignConversations = (
  campaignId: string,
  startAfter?: number,
  filters?: GetCampaignCallsFilters
) => {
  let params = {};
  if (startAfter) {
    params = { start_after: startAfter };
  }
  if (filters) {
    params = { ...params, filters: JSON.stringify(filters) };
  }

  return jwtInstance.get(`/campaigns/${campaignId}/conversations`, { params });
};

export const getCampaignConversationsMetadata = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/conversations/metadata`);
};

export const exportCampaignCalls = (
  campaignId: string,
  filters?: GetCampaignCallsFilters
) => {
  let params = {};
  if (filters) {
    params = { ...params, filters: JSON.stringify(filters) };
  }

  return jwtInstance.get(
    `${API_URL}/campaigns/${campaignId}/conversations/export`,
    {
      params,
      responseType: "blob",
    }
  );
};

export const getCampaignRole = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/users/me/role`);
};

export const getCampaignUsers = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/users`);
};

export const addCampaignUsers = (campaignId: string, userIds: string[]) => {
  return jwtInstance.post(`/campaigns/${campaignId}/users`, userIds);
};
export const updateCampaignUserRole = (
  campaignId: string,
  email: string,
  role: string,
  policy?: CampaignPolicy
) => {
  return jwtInstance.put(`/campaigns/${campaignId}/users/${email}/role`, {
    role,
    policy,
  });
};

export const deleteCampaignUser = (campaignId: string, email: string) => {
  return jwtInstance.delete(`/campaigns/${campaignId}/users/${email}`);
};
export const getUsers = () => {
  return jwtInstance.get("/users");
};

export const getUser = (userId: string) => {
  return jwtInstance.get(`/users/${userId}`);
};

// not used anymore. Use getOrganisationSupervisors instead
export const getSupervisors = () => {
  return jwtInstance.get("/users", {
    params: { role: "supervisor" },
  });
};

export const getCampaignEvaluationGrid = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/evaluation-grid`);
};

export const updateEvaluationGrid = (
  evaluationGridId: string,
  update: EvaluationGrid
) => {
  return jwtInstance.patch(`/evaluation-grids/${evaluationGridId}`, update);
};

export const getEvaluationGridItem = (
  evaluationGridId: string,
  evaluationGridItemId: string
) => {
  return jwtInstance.get(
    `/evaluation-grids/${evaluationGridId}/items/${evaluationGridItemId}`
  );
};

export const uploadTestCall = (campaignId: string, file: any) => {
  var formData = new FormData();
  formData.append("file", file);
  return jwtInstance.post(`/campaigns/${campaignId}/test-calls`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadAudioCall = (
  file: any,
  campaignId: string,
  agentId?: string
) => {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("campaign_id", campaignId);
  if (!!agentId) {
    formData.append("agent_id", agentId);
  }
  return jwtInstance.post(`/calls/audio`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadConversation = (
  campaignId: string,
  type: ConversationTypeEnum,
  inputText: string,
  agentId?: string
) => {
  return jwtInstance.post(`/campaigns/${campaignId}/conversations`, {
    type: type,
    agent_id: agentId,
    input_text: inputText,
  });
};

export const uploadEmail = (
  campaignId: string,
  emails: Email[],
  agentId?: string
) => {
  return jwtInstance.post(`/campaigns/${campaignId}/conversations`, {
    campaignId: campaignId,
    agent_id: agentId,
    emails: emails,
    type: ConversationTypeEnum.EMAIL,
  });
};

export const uploadChat = (
  campaignId: string,
  messages: Chat[],
  agentId?: string
) => {
  return jwtInstance.post(`/campaigns/${campaignId}/conversations`, {
    campaignId: campaignId,
    agent_id: agentId,
    messages: messages,
    type: ConversationTypeEnum.CHAT,
  });
};

export const getTestCalls = (campaignId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/test-calls`);
};

export const getTestCall = (campaignId: string, testCallId: string) => {
  return jwtInstance.get(`/campaigns/${campaignId}/test-calls/${testCallId}`);
};

export const testEvaluationGridItem = ({
  description,
  transcript,
  item_type,
  levels,
}: {
  description: string;
  transcript: TranscriptRow[];
  item_type: EvaluationGridTypeEnum;
  levels?: MultipleLevelsEvaluationGridItemLevel[];
}) => {
  return jwtInstance.post(`/evaluation-grids/item/test`, {
    description,
    transcript,
    item_type,
    levels,
  });
};

// not used anymore. Use getOrganisationAgents instead
export const getAgents = () => {
  return jwtInstance.get(`/agents`);
};

export const getAdminKpis = (filters: DashboardFilters) => {
  let params = {
    startTs: filters.timeRange.start.unix(),
    endTs: filters.timeRange.end.unix(),
    campaigns: filters.campaigns,
    organisations: filters.organisations,
  };

  return jwtInstance.get(`/admin/kpis`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

function dashboardFiltersToParams(filters?: DashboardFilters): any {
  let params: any = {};

  if (filters) {
    params = {
      startTs: filters.timeRange.start.unix(),
      endTs: filters.timeRange.end.unix(),
      campaigns: filters.campaigns,
      agents: filters.agents,
      topics: filters.topics,
    };
    if (!!filters.metadata) {
      let metadataFilters = filters.metadata;

      Object.keys(metadataFilters).forEach((key) => {
        params[key] = metadataFilters[key];
      });
    }
  }
  return params;
}

export const getGlobalKpis = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/kpis`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getScoreEvolutionChartData = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/score/evolution`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getScoreDistributionChartData = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/score/distribution`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getAgentsPerformanceChartData = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/agents/performance`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getAgentsTopPerformersChartData = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/agents/performance`, {
    params: {
      ...dashboardFiltersToParams(filters),
      ascending: false,
      limit: 20,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getAgentsLowPerformersChartData = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/agents/performance`, {
    params: {
      ...dashboardFiltersToParams(filters),
      ascending: true,
      limit: 20,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getTypeProportionChartData = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/type-proportion`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getEvaluationItemsDetails = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/evaluations/items/details`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getEvaluationItemsValidationStats = (
  filters?: DashboardFilters
) => {
  return jwtInstance.get(
    "/dashboards/graphs/evaluations/items/validation-stats",
    {
      params: dashboardFiltersToParams(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
};

export const getEvaluationItemUpdates = (
  campaignId: string,
  itemId: string
) => {
  return jwtInstance.get(
    `/dashboards/graphs/campaigns/${campaignId}/evaluation-items/${itemId}/updates`
  );
};

export const getCustomAnalysesDetails = (filters?: DashboardFilters) => {
  return jwtInstance.get(`/dashboards/graphs/custom-analyses/details`, {
    params: dashboardFiltersToParams(filters),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getCustomAnalysesFreeTextGraph = (
  customAnalysisId: string,
  filters?: DashboardFilters
) => {
  return jwtInstance.get(
    `/dashboards/graphs/custom-analyses-free-text/${customAnalysisId}`,
    {
      params: dashboardFiltersToParams(filters),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
};

export const getDashboardFilters = (
  get_campaigns_filter: boolean,
  get_metadata_filter: boolean
) => {
  return jwtInstance.get(`/dashboards/filters`, {
    params: { get_campaigns_filter, get_metadata_filter },
  });
};

export const getConversation = (conversationId: string) => {
  return jwtInstance.get(`/conversations/${conversationId}`);
};

export const getCallAudioDownloadUrl = (callId: string) => {
  return jwtInstance.get(`/calls/${callId}/audio/download-url`);
};

export const updateConversation = (
  callId: string,
  update: { conversation_timestamp?: number; agent_id?: string }
) => {
  return jwtInstance.patch(`/conversations/${callId}`, update);
};

export const updateCallTranscript = (
  callId: string,
  transcript: TranscriptRow[]
) => {
  return jwtInstance.put(`/calls/${callId}/transcript`, transcript);
};

export const deleteConversation = (conversationId: string) => {
  return jwtInstance.delete(`/conversations/${conversationId}`);
};

export const sendConversationDiscussionMessage = (
  conversationId: string,
  message: {
    text: string;
    users_mentioned: string[];
  }
) => {
  return jwtInstance.post(
    `/conversations/${conversationId}/discussion/message`,
    message
  );
};

export const translateConversation = (
  conversationId: string,
  target_language: string
) => {
  return jwtInstance.get(`/conversations/${conversationId}/translation`, {
    params: { target_language },
  });
};

// not used anymore. Use updateConversationEvaluationItem instead
export const updateConversationEvaluation = (
  conversationId: string,
  evaluation: Evaluation
) => {
  return jwtInstance.put(
    `/conversations/${conversationId}/evaluation`,
    evaluation
  );
};

export const validateConversationEvaluation = (conversationId: string) => {
  return jwtInstance.post(
    `/conversations/${conversationId}/evaluation/validate`
  );
};

export const unvalidateConversationEvaluation = (conversationId: string) => {
  return jwtInstance.delete(
    `/conversations/${conversationId}/evaluation/validate`
  );
};

export const updateConversationEvaluationItem = (
  conversationId: string,
  itemId: string,
  evaluationItem:
    | {
        not_applicable: boolean;
        level?: string;
      }
    | {
        not_applicable: boolean;
        followed_instructions?: boolean;
      },
  item_type: EvaluationGridTypeEnum,
  comment: string | null
) => {
  return jwtInstance.put(
    `/conversations/${conversationId}/evaluation/items/${itemId}`,
    {
      evaluation_item: evaluationItem,
      comment,
      item_type: item_type,
    }
  );
};

export const updateConversationEvaluationItemComment = (
  conversationId: string,
  itemId: string,
  comment: string | null
) => {
  return jwtInstance.put(
    `/conversations/${conversationId}/evaluation/items/${itemId}/comment`,
    { comment }
  );
};

export const launchConversationEvaluation = (conversationId: string) => {
  return jwtInstance.post(`/conversations/${conversationId}/evaluation/launch`);
};

export const launchItemEvaluation = (
  conversationId: string,
  itemId: string
) => {
  return jwtInstance.post(
    `/conversations/${conversationId}/evaluation/items/${itemId}/launch`
  );
};

export const launchCallTranscription = (callId: string) => {
  return jwtInstance.post(`/calls/${callId}/transcription/launch`);
};

export const getOrganisations = () => {
  return jwtInstance.get(`/organisations`);
};

export const getOrganisation = (organisationId: string) => {
  return jwtInstance.get(`/organisations/${organisationId}`);
};
export const createOrganisation = (organisation: { name: string }) => {
  return jwtInstance.post(`/organisations`, organisation);
};

export const getOrganisationUsers = (organisationId: string) => {
  return jwtInstance.get(`/organisations/${organisationId}/users`);
};

export const getOrganisationAgents = (organisationId: string) => {
  return jwtInstance.get(`/organisations/${organisationId}/users?role=agent`);
};

export const getOrganisationSupervisors = (organisationId: string) => {
  return jwtInstance.get(
    `/organisations/${organisationId}/users?role=supervisor`
  );
};

export const getOrganisationCampaigns = (organisationId: string) => {
  return jwtInstance.get(`/organisations/${organisationId}/campaigns`);
};

export const getUsage = (organisationId: string) => {
  return jwtInstance.get(`/organisations/${organisationId}/usage`);
};

export const inviteUser = (email: string, name: string, role: string) => {
  return jwtInstance.post(`/users/invite`, { email, name, role });
};

export const inviteUserToOrganisation = (
  organisationId: string,
  email: string,
  name: string,
  role: string,
  sendInviteEmail: boolean
) => {
  return jwtInstance.post(
    `/organisations/${organisationId}/users/invite`,
    {
      name,
      email,
      role,
    },
    {
      params: { send_invite_email: sendInviteEmail },
    }
  );
};

export const createOrgCampaign = (
  organisationId: string,
  campaign: CampaignPost
) => {
  return jwtInstance.post(
    `/organisations/${organisationId}/campaigns`,
    campaign
  );
};

export const getNotifications = async () => {
  const res = await jwtInstance.get<Notification[]>(`/notifications`);

  return res.data;
};

export const markNotificationAsRead = (notificationId: string) => {
  return jwtInstance.put(`/notifications/${notificationId}/read`);
};

export const exportConversation = (conversationId: string) => {
  return jwtInstance.get(`/conversations/export/${conversationId}`);
};
