import { Metadata } from "./metadata";
import {
  CustomAnalysisFreeText,
  CustomAnalysisMultipleChoice,
} from "./customAnalyses";
import {
  AdditionalScoreFormula,
  AdditionalScoreItemsSelection,
  BinaryEvaluationGridItem,
  ComposedEvaluationGridItem,
  MultipleLevelsEvaluationGridItem,
} from "./evaluationGrids";
import { SpeakerEnum, TranscriptQuote, TranscriptRow } from "./transcripts";

export enum TopicSentimentEnum {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

type CustomAnalysisResultBase = {
  analysis_failed: boolean;
  analysis_failed_reason?: string;
  result?: string;
  quotes?: TranscriptQuote[];
};

export type CustomAnalysisFreeTextResult = CustomAnalysisResultBase & {
  analysis: CustomAnalysisFreeText;
  not_applicable?: boolean;
  not_applicable_reason?: string;
};

export type CustomAnalysisMultipleChoiceResult = CustomAnalysisResultBase & {
  analysis: CustomAnalysisMultipleChoice;
  explanation?: string;
};

export type CustomAnalysisResult =
  | CustomAnalysisFreeTextResult
  | CustomAnalysisMultipleChoiceResult;

export type BinaryAutoEvaluation = {
  auto_evaluation_failed: boolean;
  auto_evaluation_failed_reason?: string;
  result?: {
    not_applicable: boolean;
    quotes?: TranscriptQuote[];
    comment: string;
    followed_instructions?: boolean;
  };
};

export type BinaryEvaluationResult = {
  not_applicable: boolean;
  followed_instructions?: boolean;
  comment?: string;
};

export type BinaryEvaluationItem = {
  item_definition: BinaryEvaluationGridItem;
  auto_evaluation?: BinaryAutoEvaluation;
  evaluation?: BinaryEvaluationResult;
};

export type MultipleLevelsAutoEvaluation = {
  auto_evaluation_failed: boolean;
  auto_evaluation_failed_reason?: string;
  result?: {
    not_applicable: boolean;
    quotes?: TranscriptQuote[];
    comment: string;
    level?: string;
  };
};

export type MultipleLevelsEvaluationResult = {
  not_applicable: boolean;
  level?: string;
  comment?: string;
};

export type MultipleLevelsEvaluationItem = {
  item_definition: MultipleLevelsEvaluationGridItem;
  auto_evaluation?: MultipleLevelsAutoEvaluation;
  evaluation?: MultipleLevelsEvaluationResult;
};

export type ComposedAutoEvaluation = {
  auto_evaluation_failed: boolean;
  auto_evaluation_failed_reason?: string;
  result?: {
    level?: string;
  };
};

export type ComposedEvaluationResult = {
  level?: string;
  comment?: string;
};

export type ComposedEvaluationItem = {
  item_definition: ComposedEvaluationGridItem;
  auto_evaluation: ComposedAutoEvaluation;
  evaluation?: ComposedEvaluationResult;
};

export type EvaluationItem =
  | BinaryEvaluationItem
  | MultipleLevelsEvaluationItem
  | ComposedEvaluationItem;

export type EvaluationSection = {
  title: string;
  items: EvaluationItem[];
  score: number | null;
  score_auto_evaluation: number | null;
};

export type AdditionalScoreResult = {
  definition: AdditionalScoreFormula | AdditionalScoreItemsSelection;
  name: string;
  score?: number;
  score_auto_evaluation?: number;
};

export type Evaluation = {
  global_score: number;
  global_score_auto_evaluation: number | null;
  partial_evaluation: boolean;
  sections: EvaluationSection[];
  additional_scores: AdditionalScoreResult[];
  evaluation_grid_version?: string | null;
  validated?: boolean;
  validated_timestamp?: number | null;
  validated_by?: {
    id: string;
    email: string;
    name?: string;
  };
};

export type Conversation = {
  id: string;
  created_at: number;
  campaign: {
    id: string;
    name: string;
    metadata: Metadata[];
  };
  agent?: {
    id: string;
    name: string;
    email: string;
  };
  conversation_timestamp: number;
  status: string;
  language?: string;
  summary?: string;
  topics?: { topic: string; sentiment: TopicSentimentEnum }[];
  metadata?: Metadata[];
  evaluation?: Evaluation;
  evaluated_with_current_version?: boolean;
  custom_analyses?: CustomAnalysisResult[];
  custom_analyses_version?: string | null;
  discussion: {
    sender: {
      id: string;
      name?: string;
      email: string;
    };
    text: string;
    timestamp: number;
    users_mentioned: string[];
  }[];
};

export enum ConversationTypeEnum {
  CALL = "call",
  CHAT = "chat",
  EMAIL = "email",
}

export type Call = Conversation & {
  type: ConversationTypeEnum.CALL;
  source?: string;
  presigned_url?: string;
  audio_duration?: number;
  transcript: TranscriptRow[];
};

export type Chat = {
  sender: SpeakerEnum;
  text: string;
  timestamp?: number;
};

export type ChatConversation = Conversation & {
  type: ConversationTypeEnum.CHAT;
  messages: Chat[];
};

export type Email = {
  sender: SpeakerEnum;
  subject: string;
  body: string;
  timestamp?: number;
};

export type EMailConversation = Conversation & {
  type: ConversationTypeEnum.EMAIL;
  emails: Email[];
};

export type Topic = {
  topic: string;
  sentiment: TopicSentimentEnum;
};
