import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";
import { produce } from "immer";
import { IoIosClose } from "react-icons/io";
import useBrowserLanguage from "../../hooks/useBrowserLanguage";
import useNotifications from "../../hooks/notifications";
import { markNotificationAsRead } from "../../hooks/api";
import {
  MentionedInConversation,
  Notification,
  NotificationTypeEnum,
} from "../../models/notifications";
import InputLabel from "../InputLabel";
import styles from "./Notifications.module.css";

const MentionedInConversationNotification: React.FC<{
  notification: MentionedInConversation;
}> = ({ notification }) => {
  const { getTextInBrowserLanguage } = useBrowserLanguage();
  return (
    <div>
      <span className=" font-semibold">{notification.mentioned_by.name}</span>{" "}
      {getTextInBrowserLanguage({
        en: "mentioned you in a conversation",
        fr: "vous a mentionné dans une conversation",
      })}
    </div>
  );
};

const Notifications: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    notifications,
    setNotifications,
    notificationBarOpen,
    setNotificationBarOpen,
    loading,
    error,
  } = useNotifications();
  const { getTextInBrowserLanguage } = useBrowserLanguage();
  const navigate = useNavigate();

  const markAsRead = async (notification: Notification) => {
    if (!notification.read) {
      try {
        await markNotificationAsRead(notification.id);

        setNotifications((previousNotifications) =>
          produce(previousNotifications, (draft) => {
            const index = draft.findIndex((n) => n.id === notification.id);
            if (index !== -1) {
              draft[index].read = true;
            }
          })
        );
      } catch {}
    }
  };

  useEffect(() => {
    if (containerRef.current && notificationBarOpen) {
      containerRef.current.scrollTo({
        top: 0,
      });
    }
  }, [notificationBarOpen]);

  const formatTime = (time: number) => {
    const current = dayjs();
    const date = dayjs(time * 1000);

    if (current.isSame(date, "day")) {
      return date.format("HH:mm");
    } else if (current.add(-1, "day").isSame(date, "day")) {
      return getTextInBrowserLanguage({
        en: "Yesterday",
        fr: "Hier",
      });
    } else if (current.isSame(date, "year")) {
      const diff = current.diff(date, "day");

      if (diff === 1) {
        return getTextInBrowserLanguage({
          en: "1 day ago",
          fr: "Il y a 1 jour",
        });
      } else {
        return getTextInBrowserLanguage({
          en: `${diff} days ago`,
          fr: `Il y a ${diff} jours`,
        });
      }
    } else {
      return new Date(time * 1000).toLocaleDateString();
    }
  };

  return (
    <div
      className={clsx(
        "fixed bottom-0 right-10 h-[450px] w-[350px] bg-white shadow-lg border rounded-md flex flex-col",
        "transition-all duration-300 ease-in-out transform z-20",
        {
          "translate-y-0": notificationBarOpen,
          "translate-y-full": !notificationBarOpen,
        }
      )}
    >
      <div className="border-b p-3 flex flex-row items-center shadow-sm z-10">
        <div className="grow">
          <InputLabel>Notifications</InputLabel>
        </div>
        <div
          className="cursor-pointer text-gray-400 hover:text-gray-800 hover:scale-110"
          onClick={() =>
            setNotificationBarOpen && setNotificationBarOpen(false)
          }
        >
          <IoIosClose className="w-6 h-6" />
        </div>
      </div>
      <div
        className={clsx(
          "w-full mx-auto flex flex-col overflow-y-auto",
          styles.container
        )}
        ref={containerRef}
      >
        {loading && (
          <div className="text-center text-gray-400 mt-6 text-sm">
            {getTextInBrowserLanguage({
              en: "Loading...",
              fr: "Chargement...",
            })}
          </div>
        )}
        {!loading && error && (
          <div className="text-center text-gray-400 mt-6 text-sm">
            {getTextInBrowserLanguage({
              en: "Error loading notifications.",
              fr: "Erreur lors du chargement des notifications.",
            })}
          </div>
        )}
        {!loading && !error && notifications && notifications?.length === 0 && (
          <div className="text-center text-gray-400 mt-6 text-sm ">
            {getTextInBrowserLanguage({
              en: "No notifications.",
              fr: "Pas de notifications.",
            })}
          </div>
        )}
        {!loading &&
          !error &&
          notifications?.map((notification) => (
            <div
              className={clsx(
                "border-b border-purple-100  p-5 cursor-pointer  text-gray-600 hover:bg-purple-100 text-sm",
                !notification.read && "bg-purple-50",
                notification.read && "bg-white"
              )}
              onClick={() => {
                setNotificationBarOpen && setNotificationBarOpen(false);

                if (
                  notification.type ===
                  NotificationTypeEnum.MENTIONED_IN_CONVERSATION
                ) {
                  const mentionedInConversation =
                    notification as MentionedInConversation;
                  navigate(
                    `/conversations/${mentionedInConversation.conversation_id}`
                  );
                }
                markAsRead(notification);
              }}
              key={notification.id}
            >
              {notification.type ===
                NotificationTypeEnum.MENTIONED_IN_CONVERSATION && (
                <MentionedInConversationNotification
                  notification={notification as MentionedInConversation}
                />
              )}
              <div className="text-xs text-gray-400 mt-1">
                {formatTime(notification.created_at)}
              </div>
            </div>
          ))}

        {!loading && !error && notifications.length > 5 && (
          <div className="text-center text-gray-400 my-3 text-xs ">
            {getTextInBrowserLanguage({
              en: "No more notifications.",
              fr: "Pas de notifications supplémentaires.",
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
