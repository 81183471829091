import { useEffect, createContext, useState } from "react";
import { getNotifications } from "../hooks/api";
import { Notification } from "../models/notifications";
import { useWebSocket } from "../App";

import useAccess from "../hooks/access";
import useIsMobile from "../hooks/useIsMobile";

const NotificationsContext = createContext<{
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  hasNewNotifications: boolean;
  setHasNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  notificationBarOpen: boolean;
  setNotificationBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleNotificationBarOpen: () => void;
  error: boolean;
  loading: boolean;
}>({
  notifications: [],
  setNotifications: () => {},
  hasNewNotifications: false,
  setHasNewNotifications: () => {},
  notificationBarOpen: false,
  setNotificationBarOpen: () => {},
  toggleNotificationBarOpen: () => {},
  error: false,
  loading: false,
});

const NotificationsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const ws = useWebSocket();
  const { isAdmin } = useAccess();
  const isMobile = useIsMobile();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [notificationBarOpen, setNotificationBarOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleNotificationBarOpen = () => {
    setNotificationBarOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isAdmin || isMobile) return;

    setLoading(true);
    setError(false);

    getNotifications()
      .then((res) => {
        setNotifications(res);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isAdmin, isMobile]);

  useEffect(() => {
    if (!notificationBarOpen || isAdmin || isMobile) return;

    setLoading(true);
    setError(false);

    getNotifications()
      .then((res) => {
        setNotifications(res);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [notificationBarOpen, isAdmin, isMobile]);

  useEffect(() => {
    setHasNewNotifications(
      notifications.some((notification) => !notification.read)
    );
  }, [notifications]);

  useEffect(() => {
    if (ws && !isAdmin && !isMobile) {
      ws.onmessage = async () => {
        setHasNewNotifications(true);

        setLoading(true);
        setError(false);

        try {
          setNotifications(await getNotifications());
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };
    }

    return () => {
      if (ws) {
        ws.onmessage = null;
      }
    };
  }, [ws, isAdmin, isMobile]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        hasNewNotifications,
        setHasNewNotifications,
        notificationBarOpen,
        setNotificationBarOpen,
        toggleNotificationBarOpen,
        error,
        loading,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
export { NotificationsContext };
