import { Tooltip } from "antd";

interface InputLabelProps {
  tooltip?: string;
}

const InputLabel: React.FC<React.PropsWithChildren<InputLabelProps>> = ({
  children,
  tooltip,
}) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span className="text-gray-800 text-sm font-bold">{children}</span>
      </Tooltip>
    );
  }

  return <span className="text-gray-800 text-sm font-bold">{children}</span>;
};

export default InputLabel;
