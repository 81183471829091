import { UserBase } from "./users";

type NotificationBase = {
  id: string;
  user_id: string;
  read: boolean;
  created_at: number;
};

export enum NotificationTypeEnum {
  MENTIONED_IN_CONVERSATION = "MENTIONED_IN_CONVERSATION",
}

export type MentionedInConversation = NotificationBase & {
  type: NotificationTypeEnum.MENTIONED_IN_CONVERSATION;
  conversation_id: string;
  mentioned_by: UserBase;
};

export type Notification = MentionedInConversation;
