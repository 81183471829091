import { useContext } from "react";
import useWindowSize from "./useWindowSize";
import { LayoutContext } from "../components/Layout";

function getContentWidth(windowWidth: number, leftMenuCollapsed: boolean) {
  let usableWindowWidth = windowWidth - (leftMenuCollapsed ? 64 : 240);
  if (usableWindowWidth < 630) {
    return "100%";
  }

  if (usableWindowWidth > 1500) {
    return 1300;
  }

  return usableWindowWidth - 100;
}

const useLayout = () => {
  const { leftMenu } = useContext(LayoutContext);
  const { width: windowWidth } = useWindowSize();

  const isCollapsed = !leftMenu.forceExpanded && !leftMenu.hovered;
  return {
    leftMenu: {
      ...leftMenu,
      isCollapsed,
    },
    contentWidth: getContentWidth(
      windowWidth,
      !leftMenu.forceExpanded && !leftMenu.hovered
    ),
  };
};

export default useLayout;
