import { useContext } from "react";
import { NotificationsContext } from "../components/NotificationsProvider";

const useNotifications = () => {
  const notificationsContext = useContext(NotificationsContext);

  if (!notificationsContext) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider"
    );
  }

  return notificationsContext;
};

export default useNotifications;
