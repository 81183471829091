import React from "react";
import useBrowserLanguage from "../hooks/useBrowserLanguage";

const OfflineNotice: React.FC = () => {
  const { getTextInBrowserLanguage } = useBrowserLanguage();
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 mt-[-150px]">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            {getTextInBrowserLanguage({
              fr: "Vous êtes hors ligne.",
              en: "You are offline.",
            })}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {getTextInBrowserLanguage({
              fr: "Veuillez vérifier votre connexion internet.",
              en: "Please check your internet connection.",
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfflineNotice;
