import React from "react";
import { Link } from "react-router-dom";
import useBrowserLanguage from "../hooks/useBrowserLanguage";

const NotFound: React.FC = () => {
  const { getTextInBrowserLanguage } = useBrowserLanguage();
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <h1 className="text-6xl font-bold text-gray-800">404</h1>
      <p className="text-2xl text-gray-700 mt-4 mb-8">
        {getTextInBrowserLanguage({
          en: "Page not found",
          fr: "Page non trouvée",
        })}
      </p>
      <Link to="/" className="text-gray-500 hover:text-gray-800">
        {getTextInBrowserLanguage({
          en: "Go back home",
          fr: "Retourner à l'accueil",
        })}
      </Link>
    </div>
  );
};

export default NotFound;
