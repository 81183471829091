import React from "react";
import logo from "./../logo_allobrain_white.svg";
import useBrowserLanguage from "../hooks/useBrowserLanguage";

const Error500: React.FC = () => {
  const { getTextInBrowserLanguage } = useBrowserLanguage();
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="bg-black rounded-lg px-6 py-3 mb-3">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Allobrain" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {getTextInBrowserLanguage({
              fr: "Nous avons rencontré une erreur.",
              en: "We've encountered an error.",
            })}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {getTextInBrowserLanguage({
              fr: "Nous sommes désolés, mais une erreur s'est produite de notre côté.",
              en: "We're sorry, but something went wrong on our end.",
            })}
          </p>

          <p className="mt-2 text-center text-sm text-gray-600">
            {getTextInBrowserLanguage({
              fr: "Veuillez réessayer plus tard.",
              en: "Please try again later.",
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error500;
