import { useCallback, useContext } from "react";
import { UserContext } from "../App";
import { QualityMonitoringPolicy } from "../models/users";
import { CampaignPolicy, CampaignRoleEnum } from "../models/campaignUsers";

export default function useAccess() {
  const { currentUser } = useContext(UserContext);

  const can = useCallback(
    (permission: keyof QualityMonitoringPolicy) => {
      if (!currentUser) return false;

      if (currentUser.admin) return true;

      return currentUser?.config?.quality_monitoring.policy[permission];
    },
    [currentUser]
  );

  const role = currentUser?.config?.quality_monitoring?.role;

  const isAdmin = currentUser?.admin || false;

  const campaignAccess = useCallback(
    (
      permission: keyof CampaignPolicy,
      campaignRole:
        | {
            role: CampaignRoleEnum | "admin";
            policy?: CampaignPolicy;
          }
        | undefined
    ) => {
      if (!currentUser) return false;

      if (currentUser.admin) return true;

      if (!campaignRole) return false;

      if (campaignRole.role === CampaignRoleEnum.owner) return true;

      if (campaignRole.role === CampaignRoleEnum.viewer) return false;

      if (!campaignRole.policy) {
        return (
          permission !== "can_delete_conversations" &&
          permission !== "can_unvalidate_evaluations"
        );
      }

      return campaignRole.policy[permission] || false;
    },
    [currentUser]
  );

  return { can, role, isAdmin, campaignAccess };
}
