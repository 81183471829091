import { useCallback } from "react";

function useBrowserLanguage() {
  const getTextInBrowserLanguage = useCallback(
    (textDict: { [key: string]: string }) => {
      let browserLanguage = navigator.language.split("-")[0];

      if (textDict.hasOwnProperty(browserLanguage)) {
        return textDict[browserLanguage];
      }

      return textDict["en"];
    },
    []
  );

  const browserLanguage = navigator.language.split("-")[0];

  return { getTextInBrowserLanguage, browserLanguage };
}

export default useBrowserLanguage;
